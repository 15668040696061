import React from "react"
import Seo from "../components/seo"
import Home from "../components/Content/Home"

export default props => (
  <React.Fragment>
    <Seo
      title={`Nicaragua Tours`}
      description={`We offer a full service of Nicaragua Tours. Discover the most popular destinations in Nicaragua like Granada, Mombacho Volcano, Masaya with us.`}
    />
    <Home location={props.location} langKey={props.pageContext.langKey} />
  </React.Fragment>
)
